<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "ContactUs",

  metaInfo: { title: "Hubungi Kami" },

  extends: View,

  mixins: [
    LoadSections([
      // "hero-alt",
      "contact-us",
      "map",
      "info",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
